var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_vm._v("The Riptide Rush flavor of Gatorade has the following absorption spectrum:")]),_c('p',{staticClass:"mb-4 pl-6"},[_c('v-img',{staticStyle:{"max-width":"500px"},attrs:{"src":"/img/assignments/gatoradeGraph.png"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" a) What color dyes are present in this Gatorade? "),_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" b) Does each dye have it's own "),_c('stemble-latex',{attrs:{"content":"$\\lambda_{\\text{max}}?$"}}),_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" c) If your Beer's Law Plot was for the red dye, at what wavelength would you take the absorbance to determine the concentration of red dye in this flavor of Gatorade? "),_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }